import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs"
import React from "react";

export default function FontSize(props){
  const {size, onChange} = props
  const { silentUpdateUserPrefs } = useUserPrefs();

  const sizes = ['s', 'm', 'l']
  const index = sizes.findIndex(el=>el===size)

  const onMouseLeave = ()=>{
    silentUpdateUserPrefs()
  }

  const handlerOnClick = ()=>{
    let nextIndex = index>=2?0:index+1
    onChange(sizes[nextIndex])
  }

  return(
    <div className="font-size" onMouseLeave={onMouseLeave}>
     <button onClick={handlerOnClick}>
        <span style={{fontSize: '8px'}} className={size==='s'?'checked':''}>A</span>
        <span style={{fontSize: '12px'}} className={size==='m'?'checked':''} onClick={()=>onChange('m')}>A</span>
        <span style={{fontSize: '16px'}} className={size==='l'?'checked':''} onClick={()=>onChange('m')}>A</span>
      </button>
    </div>
  )
}
